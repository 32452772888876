import * as React from "react";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Project } from "../../types/project";
import { AddAlarmOutlined, UpdateOutlined } from "@mui/icons-material";
import { useGetProjectByIdQuery } from "../../services/project.service";
import ModalShiftForm from "./ModalShiftForm";
import { Shift } from "../../types/shift";
import { useGetProjectShiftsQuery } from "../../services/projectShift.service";
import dayjs from "dayjs";
import Edit from "@mui/icons-material/Edit";
import DeleteOutline from "@mui/icons-material/DeleteOutline";

const ProjectShiftsPanel = ({ project }: { project: Project }) => {
  const navigate = useNavigate();
  //const { data: shifts } = useGetProjectShiftsQuery({ project_id: project.id });
  const [shiftModal, setShiftModal] = React.useState<{
    open: boolean;
    data: Shift | null;
  }>({
    open: false,
    data: null,
  });
  const shifts = React.useMemo(() => project?.shifts || [], [project]);

  const [deleteModal, setDeleteModal] = React.useState<{
    open: boolean;
    shift: Shift | null;
  }>({
    open: false,
    shift: null,
  });
  // const [doDelete, { isLoading: deleting }] = useDeleteManagerMutation();

  const handleDeleteRow = (shift: Shift) => {
    setDeleteModal({ open: true, shift });
  };
  const handleEditRow = (shift: Shift) => {
    setShiftModal({ open: true, data: shift });
  };

  const getTimeInfo = React.useCallback(
    (start?: Date, end?: Date, timezone?: string) => {
      let t1 = dayjs(dayjs(start).format("YYYY-MM-DD"));
      let t2 = dayjs(dayjs(end).format("YYYY-MM-DD"));
      const now = dayjs();
      if (now.isAfter(t2)) {
        return (
          <Typography variant="subtitle2" color="blue">
            Shift Finished ({dayjs(t2).format("MMM DD")})
          </Typography>
        );
      } else if (now.isBefore(t1)) {
        return (
          <Typography variant="subtitle2" color="secondary">
            Not Started Yet ({dayjs(t1).format("MMM DD")})
          </Typography>
        );
      } else {
        return (
          <Typography variant="subtitle2" color={"primary"}>
            Active
          </Typography>
        );
      }
    },
    [project]
  );
  const getRepeatInfo = React.useCallback(
    (shift?: Shift) => {
      if (shift?.repeat == "never") {
        return null;
      } else {
        // occcurence
        return (
          <Box>
            <Typography variant="caption">
              {shift?.repeat == "this week"
                ? "This Week Only"
                : "Repeat " + shift?.repeat}
            </Typography>
            <Stack direction={"row"}>
              <Chip
                label="M"
                variant={!!shift?.is_monday ? "filled" : "outlined"}
                color={!!shift?.is_monday ? "secondary" : undefined}
              />
              <Chip
                label="T"
                variant={!!shift?.is_tuesday ? "filled" : "outlined"}
                color={!!shift?.is_tuesday ? "secondary" : undefined}
                sx={{ ml: 1 }}
              />
              <Chip
                label="W"
                variant={!!shift?.is_wednesday ? "filled" : "outlined"}
                color={!!shift?.is_wednesday ? "secondary" : undefined}
                sx={{ ml: 1 }}
              />
              <Chip
                label="T"
                variant={!!shift?.is_thursday ? "filled" : "outlined"}
                color={!!shift?.is_thursday ? "secondary" : undefined}
                sx={{ ml: 1 }}
              />
              <Chip
                label="F"
                variant={!!shift?.is_friday ? "filled" : "outlined"}
                color={!!shift?.is_friday ? "secondary" : undefined}
                sx={{ ml: 1 }}
              />
              <Chip
                label="S"
                variant={!!shift?.is_saturday ? "filled" : "outlined"}
                color={!!shift?.is_saturday ? "secondary" : undefined}
                sx={{ ml: 1 }}
              />
              <Chip
                label="S"
                variant={!!shift?.is_sunday ? "filled" : "outlined"}
                color={!!shift?.is_sunday ? "secondary" : undefined}
                sx={{ ml: 1 }}
              />
            </Stack>
          </Box>
        );
      }
    },
    [project]
  );

  return (
    <Box sx={{ bgcolor: "background.paper", width: "auto" }}>
      <Button
        onClick={() => setShiftModal({ open: true, data: null })}
        startIcon={<AddAlarmOutlined />}
        variant="outlined"
      >
        Create New Shift
      </Button>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table sx={{ minWidth: 650 }}>
          <TableBody>
            {shifts.map((shift, idx) => (
              <TableRow
                key={idx}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Box>
                    <Typography variant="h6">{shift.name}</Typography>
                    {getTimeInfo(
                      shift.start_date,
                      shift.end_date,
                      shift.timezone
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography variant="caption" color={"grey"} sx={{ mb: 1 }}>
                      Shift Time
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"}>
                      <UpdateOutlined fontSize={"small"} sx={{ mr: 1 }} />
                      <Typography>
                        {dayjs(shift.start_date).format("hh:mm A")} →{" "}
                        {dayjs(shift.end_date).format("hh:mm A")}
                      </Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="caption" color={"grey"} sx={{ mb: 1 }}>
                    Talents Required
                  </Typography>
                  <Typography>{shift.num_of_talent}</Typography>
                </TableCell>
                <TableCell>{getRepeatInfo(shift)}</TableCell>
                <TableCell>
                  <Stack direction={"row"}>
                    <IconButton onClick={() => handleEditRow(shift)}>
                      <Edit fontSize="small" />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteRow(shift)}>
                      <DeleteOutline fontSize="small" />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalShiftForm
        open={shiftModal.open}
        onClose={() => setShiftModal({ open: false, data: null })}
        project={project}
        data={shiftModal.data}
        onAdd={() => setShiftModal({ open: false, data: null })}
      />
    </Box>
  );
};
export default ProjectShiftsPanel;
